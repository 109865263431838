<template>
    <div>
        <fromSearch :object_='addr_nft' @searchFun='getList' />
        <el-table :data="page.list" border v-loading='listLoading' height="300">
            <el-table-column prop="selection" :label="$t('currencySetNft.text8')" width="80" >
                <template #default="scope">
                    <el-checkbox size="large" @change="changeFun(scope.row)" v-model='checkItem'/>
                </template>
            </el-table-column>
            <el-table-column prop="chain" :label="$t('currencySetNft.text6')" width="200" />
            <el-table-column prop="walletName" :label="$t('otherWallatNft.name1')" />
            <el-table-column prop="walletAddress" :label="$t('otherWallatNft.text6')" />
            <el-table-column prop="amount" :label="$t('otherWallat.name5')" />
        </el-table>
        <pageCom :page='page' @sizeChange='sizeChange' @currentChange='currentChange'/>
    </div>
</template>
<script setup>
    import { ref,defineEmits,defineExpose } from 'vue'
    import { tableOption1 } from "@/const/crud/cryptocurrency/customerWithdrawalArr";
    import mixins from '@/views/mixins/page'
    import { addr_nft_ } from '@/const/from/nft/nftWithdrawUser'
    import fromSearch from '@/components/fromSearch.vue'
    import pageCom from '@/components/pageCom.vue'

    let pageObj = mixins(['getAssetswalletinfoList'])
    const {page,listLoading,sizeChange,currentChange,getList,updateOtherParm} = pageObj
    const checkItem = ref(false)
    
    const tableOption = ref({});
    tableOption.value = tableOption1(false)
    const emit = defineEmits(['getCoin1'])


    const addr_nft = ref(addr_nft_);
    const changeFun = (e)=>{
        emit('getCoin1',e)
    }
    const init_ = (e)=>{
        checkItem.value = false
        updateOtherParm(e)
        getList(e)
    }
    defineExpose({init_})
    
</script>

<style lang="scss" scoped>
</style>